<template>
  <v-navigation-drawer v-model="drawer" :width="sidebarWidth" app floating :mobile-breakpoint="1024">
    <sidebar-menu :items="menu" />

    <template v-slot:prepend>

      <router-link :to="{ name: 'Home' }">
        <v-hover>
          <v-sheet tile :width="sidebarWidth" :height="headerHeight" class="d-flex align-center" color="transparent">
            <!-- <div class="mr-4" style="background: url('https://www.metalprinting.hu/wp-content/uploads/2019/11/sze_logo_blue-300x100.png') no-repeat center left; background-size: cover; width: 50px; height: 50px; filter: brightness(10)"></div> -->
            <!-- <v-icon size="36" class="mx-4">mdi-shield-key-outline</v-icon> -->


            <img width="36" height="36" class="ml-4 mr-2" contain
              :src="require($store.state.darkMode ? '../../assets/logo-dark.svg' : '../../assets/logo.svg')" />
            <div class="text-left">
              <div class="font-weight-bold text-uppercase mb-n1">Széchenyi Egyetem</div>
              <div class="caption">Beléptetőrendszer</div>
            </div>
          </v-sheet>
        </v-hover>
      </router-link>

      <!-- <router-link :to="{ name: 'Home' }">
        <v-hover v-slot="{ hover }">
          <v-sheet
            fab
            tile
            :width="sidebarWidth"
            :height="headerHeight"
            :color="`primary darken-${hover ? 1 : 5}`"
            dark
            class="d-flex align-center"
          >
            <v-icon size="36" class="mx-4">mdi-shield-key-outline</v-icon>
            <div class="text-left">
              <div class="font-weight-bold">Széchenyi Egyetem</div>
              <div class="caption">Beléptetőrendszer</div>
            </div>
          </v-sheet>
        </v-hover>
      </router-link> -->
      <!-- <v-sheet height="4" color="accent"></v-sheet> -->
    </template>
    <template v-slot:append>
      <v-sheet color="transparent" flat dark :height="footerHeight" class="d-flex justify-center align-center pa-3">
        <v-btn color="green" text large depressed block @click="$logout">
          <v-icon left>mdi-exit-run</v-icon>
          Exit
        </v-btn>
      </v-sheet>
    </template>
  </v-navigation-drawer>
</template>

<script>
import { version } from '@/../package.json';
import menu from '../../menu';
import SidebarMenu from '@/components/SidebarMenu.vue';

export default {
  components: {
    SidebarMenu,
  },
  data: () => ({
    width: 80,
    version,
    menu,
  }),
  methods: {
    matchRoute(name) {
      return !!this.$route.matched.filter((e) => e.path && e.name === name).length;
    },
    matchChildRoute(name) {
      return !!this.$route.matched.filter((e) => e.path && e.name === name).length;
    },
    // hasRight(name) {
    //   if (!name) {
    //     return true;
    //   }
    //   const right = this.findRight(this.$store.state.rights, name);
    //   if (right && (right.selected || this.lodash.find(right.children, { selected: true}))) {
    //     return true;
    //   }
    //   return false;
    // },

    // findRight(rights, alias) {
    //   let result = null;

    //   for (const right of rights) {
    //     if (right.alias === alias) {
    //       result = right;
    //       break;
    //     }
    //     if (right.children) {
    //       result = this.findRight(right.children, alias);
    //       if (result) {
    //         break;
    //       }
    //     }
    //   }

    //   return result;
    // },
  },
  computed: {
    headerHeight() {
      return parseInt(process.env.VUE_APP_HEADER_HEIGHT);
    },
    footerHeight() {
      return parseInt(process.env.VUE_APP_FOOTER_HEIGHT);
    },
    sidebarWidth() {
      return parseInt(process.env.VUE_APP_SIDEBAR_WIDTH);
    },
    drawer: {
      get() {
        return this.$store.state.drawer;
      },
      set(value) {
        this.$store.commit('setDrawer', value);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.v-list-group--active {
  background: #fff;
  // box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%),
  //   0px 1px 5px 0px rgb(0 0 0 / 12%);
}
</style>
